import { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Select,
  FormControl,
  MenuItem,
  Button,
  IconButton,
  DialogTitle,
  Dialog,
} from "@mui/material";
import moment from "moment";
import { getUserList } from "../../store/thunk/userThunk";
import { useAppSelector, useAppDispatch } from "../../store/store";
import "./index.css";

const FormVersion = (props: any) => {
  let {
    billingEntities,
    formState,
    formInputChange,
    formNameList,
    formVersionList: formVersions,
    addNewForm,
  } = props;
  const dispatch: any = useAppDispatch();
  const [isDialog, setIsDialog] = useState(false);
  const [formName, setFormName] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const { formJsonData, ...resss } = useAppSelector(
    (state: any) => state?.formReducer
  );

  useEffect(() => {
    const userAPICall = async () => {
      await dispatch(getUserList()).then((res: any) => {
        const responseData = res?.payload?.data;
        if (responseData) {
          responseData?.map((data: any, index: number) => {
            if (formJsonData?.createdBy == data?.id) {
              setCreatorName(data.email);
            }
          });
        }
      });
    };

    userAPICall();
  }, [formJsonData]);

  const handleClose = () => {
    setIsDialog(false);
  };
  const handleOpen = () => {
    setIsDialog(true);
  };

  const handleFormName = (event: any) => {
    let formName = event.target.value;
    setFormName(formName);
  };

  const addName = () => {
    addNewForm(formState.billingEntityId, formName);
    handleClose();
  };

  return (
    <div className="flex items-center align-center mb-[16px] overflow-auto">
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ fontWeight: "700" }}>Billing Entity</label>
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small1"
            value={formState?.billingEntityId}
            label="Billing Entity"
            name="billingEntityId"
            onChange={formInputChange}
          >
            {billingEntities &&
              billingEntities?.map((entity: any) => {
                return (
                  <MenuItem value={entity.id}>{entity.billingName}</MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>

      <div style={{ display: "flex", alignItems: "center", marginRight: 0 }}>
        <label style={{ fontWeight: "700" }}>Case Type</label>
        <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
          <Select
            labelId="demo-select-small-label1"
            id="demo-select-small1"
            value={formState?.flow || ""}
            label="name"
            name="flow"
            onChange={formInputChange}
          >
            {formNameList &&
              formNameList.length > 0 &&
              formNameList.map((list: any, index: any) => {
                return (
                  <MenuItem key={index} value={list}>
                    {list.title + " - " + list.version + ""}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: 24,
          alignSelf: "center",
        }}
        className="form-add-btn"
      >
        <IconButton
          style={{
            background: "#ccc",
            height: "31px",
            width: "31px",
          }}
          aria-label="delete"
          onClick={handleOpen}
        >
          +
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ fontWeight: "700" }}>Form Version</label>
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small1"
            value={formState?.version}
            label="Version"
            name="version"
            onChange={formInputChange}
          >
            {formVersions &&
              formVersions?.map((formVersion: any) => {
                return (
                  <MenuItem value={formVersion}>
                    {formVersion.version +
                      " - " +
                      moment(formVersion.createdDtUtc).format("MM/DD/YYYY")}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>

      <div style={{ display: "flex", alignItems: "center", marginRight: 0 }}>
        <label style={{ fontWeight: "700" }}>Created By: </label>
        <p>{creatorName}</p>
      </div>

      <Dialog onClose={handleClose} open={isDialog}>
        <DialogTitle>Create Case Type</DialogTitle>
        <Box
          component="form"
          style={{ padding: "0 20px", width: "350px" }}
          sx={{
            "& > :not(style)": { m: 1, width: "300px" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            name="formName"
            onChange={handleFormName}
          />
          <Button variant="contained" onClick={addName}>
            Add
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default FormVersion;
