import { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Button,
  List,
  ListItem,
  CircularProgress,
  DialogTitle,
  Dialog,
  Tabs,
  Tab,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import moment from "moment";

import { useAppDispatch, useAppSelector, RootState } from "../../store/store";
import { refreshUserData, getAllUIUsers } from "../../store/thunk/refreshThunk";

import RightIcon from "../../assets/images/right-icon.svg";
import { formatDate, logFunction } from "../../helper/service";

interface UserDataType {
  id: number;
  email: string;
  auth0Name: string;
  auth0Nickname: string;
  auth0Subject: string;
  createDtUtc: Date;
  isDeleted: boolean;
  isDisabled: boolean;
  isVerified: boolean;
  lastLoginDtUtc: Date;
  progressPercentage: number;
  userType: string;
}

const Refreshflow = () => {
  const dispatch = useAppDispatch();

  const { allUiUser } = useAppSelector(
    (state: RootState) => state.refreshReducer
  );
  const loading = useAppSelector(
    (state: RootState) => state.notificationReducer.loading
  );

  const [userId, setUserId] = useState<any>([]);
  const [uiUserData, setUiUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const [runningUser, setRunningUser] = useState(null);
  const [refreshed, setRefreshed] = useState<any>([]);
  const [errRefreshed, setErrRefreshed] = useState<any>([]);
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(getAllUIUsers());
  }, []);

  useEffect(() => {
    setUiUserData(allUiUser);
  }, [allUiUser]);

  const makeAPICalls = async (numberOfCalls: any) => {
    let tempArr: any = [];
    let errTempArr: any = [];

    setOpen(true);
    for (let i = 0; i < numberOfCalls?.length; i++) {
      setRunningUser(numberOfCalls[i]?.id);

      await dispatch(refreshUserData(numberOfCalls[i].id)).then((res: any) => {
        if (res?.payload?.data === "Data updated") {
          tempArr.push(numberOfCalls[i]?.id);
        } else {
          errTempArr.push(numberOfCalls[i]?.id);
        }
      });

      setRefreshed(tempArr);
      setErrRefreshed(errTempArr);

      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    setRunningUser(null);
  };

  const handleSubmit = async () => {
    if (userId?.length) {
      await makeAPICalls(userId);
    }
  };

  const userModal = () => {
    const handleClose = () => {
      setUserId([]);
      setRefreshed([]);
      setOpen(false);
    };

    return (
      <Dialog maxWidth="sm" sx={{ padding: 10 }} fullWidth open={open}>
        <DialogTitle>Refreshing Users</DialogTitle>
        <hr />
        <div style={{ overflowY: "scroll" }}>
          <List sx={{ p: 3 }}>
            {userId?.map((user: any) => (
              <ListItem
                key={user.id}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography sx={{ fontSize: "18px" }}>
                  {"User Id: " + user?.id + " - " + user?.auth0Email}
                </Typography>

                {refreshed?.includes(user?.id) ? (
                  <img
                    src={RightIcon}
                    width="40px"
                    height="40px"
                    style={{ zIndex: "5", position: "relative" }}
                  />
                ) : runningUser === user?.id ? (
                  <CircularProgress size={28} />
                ) : errRefreshed?.includes(user?.id) ? (
                  <Typography sx={{ fontSize: "18px", color: "#d32f2f" }}>
                    Error
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "18px", color: "#ff9800" }}>
                    Waiting...
                  </Typography>
                )}
              </ListItem>
            ))}
          </List>
        </div>
        <Button
          sx={{ width: "25%", alignSelf: "end", mx: "16px", my: "16px" }}
          variant="contained"
          onClick={handleClose}
          size="medium"
        >
          Close
        </Button>
      </Dialog>
    );
  };

  const commonOptions = {
    sort: true,
    customBodyRender: (value: string) => (value ? value : "--"),
  };

  const columns = [
    { name: "id", label: "Id", options: { ...commonOptions } },
    { name: "auth0Nickname", label: "Name", options: { ...commonOptions } },
    { name: "email", label: "Email", options: { ...commonOptions } },
    { name: "userType", label: "Usertype", options: { ...commonOptions } },
    {
      name: "progressPercentage",
      label: "Progress %",
      options: {
        ...commonOptions,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        customBodyRender: (value: any) =>
          value !== null && value !== undefined ? value : "--",
      },
    },
    {
      name: "stripeStatus",
      label: "Stripe Status",
      options: { ...commonOptions },
    },
    {
      name: "isVerified",
      label: "Verified",
      options: {
        ...commonOptions,
        setCellProps: () => ({ style: { minWidth: "120px" } }),
        customBodyRender: (value: any) => (
          <span
            className={`bg-${value ? "green" : "yellow"}-100 text-${
              value ? "green" : "yellow"
            }-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-${
              value ? "green" : "yellow"
            }-300 dark:text-${value ? "green" : "yellow"}-900`}
          >
            {value ? "Verified" : "Unverified"}
          </span>
        ),
      },
    },
    {
      name: "isDisabled",
      label: "Disabled",
      options: {
        sort: false,
        customBodyRender: (value: any) => (
          <Typography sx={{ fontWeight: "700" }}>
            {value ? "Yes" : "No"}
          </Typography>
        ),
      },
    },
    {
      name: "createDtUtc",
      label: "Created at",
      options: {
        ...commonOptions,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        customBodyRender: (value: any) => (
          <span>{moment(value).format("MM-DD-YYYY")}</span>
        ),
      },
    },
    {
      name: "lastLoginDtUtc",
      label: "Last login at",
      options: {
        ...commonOptions,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        customBodyRender: (value: any) => <span>{formatDate(value)}</span>,
      },
    },
  ];

  // Options for table
  const options = {
    sort: true,
    filter: false,
    print: false,
    download: false,
    search: true,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    count: allUiUser?.length,
    rowsPerPage: 10,
    selectableRowsHeader: true,
    pagination: true,
  };

  const handleSort = (column: any, order: any) => {
    const sortedData = [...uiUserData];
    sortedData?.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (order === "asc") {
        return aValue < bValue ? -1 : 1;
      } else {
        return bValue < aValue ? -1 : 1;
      }
    });

    // Update the state with the sorted data
    setUiUserData(sortedData);
  };

  // Handle search change
  const handleSearchChange = (searchText: string | null) => {
    const searchedUsers = searchText
      ? allUiUser?.filter((user: UserDataType) =>
          user?.email?.includes(searchText)
        )
      : allUiUser;

    setUiUserData(searchedUsers);
  };

  const headerElement: any = document.getElementsByTagName("header");
  const bannerElement: any = document.getElementsByClassName("banner-height");
  const refreshButtonElement: any =
    document.getElementsByClassName("refresh-button");

  const headerHeight = bannerElement
    ? bannerElement[0]?.clientHeight + headerElement[0]?.clientHeight
    : refreshButtonElement
    ? refreshButtonElement[0]?.clientHeight + headerElement[0]?.clientHeight
    : bannerElement && refreshButtonElement
    ? bannerElement[0]?.clientHeight +
      refreshButtonElement[0]?.clientHeight +
      headerElement[0]?.clientHeight
    : headerElement[0]?.clientHeight || 0;

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        className="header-tabbar"
      >
        <Tab label="Refresh" value={"1"} />
      </Tabs>
      {value === "1" && (
        <Card
          className={`relative m-5  h-[calc(100vh_-_120px)] md:h-[calc(100vh_-_84px)]`}
        >
          {userModal()}
          {userId && userId?.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                disabled={loading}
                variant="contained"
                className="xl:w-3/12 refresh-button"
                onClick={handleSubmit}
                size="large"
              >
                Refresh selected users
              </Button>
            </div>
          )}
          <div
            className="table-refresh-user-list"
            style={{
              height:
                userId && userId?.length > 0
                  ? `calc(100vh - ${headerHeight}px - 112px )`
                  : `calc(100vh - ${headerHeight}px - 60px )`,
              overflowY: "scroll",
            }}
          >
            <MUIDataTable
              title={"Refresh APIs"}
              data={uiUserData}
              columns={columns}
              options={{
                ...options,
                onColumnSortChange: handleSort,
                onSearchChange: handleSearchChange,
                onRowsDelete() {
                  setUserId([]);
                },
                onTableChange: (action, dataObj) => {
                  let copyArr: any = [];
                  if (action === "rowSelectionChange") {
                    if (dataObj?.selectedRows?.data?.length > 0) {
                      dataObj?.selectedRows?.data?.forEach((value: any) =>
                        copyArr.push({
                          id: dataObj.data[value.index].data[0],
                          auth0Email: dataObj.data[value.index].data[2],
                        })
                      );
                      setUserId(copyArr);
                    } else {
                      setUserId([]);
                      logFunction("No rows selected");
                    }
                  }
                },
              }}
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default Refreshflow;
