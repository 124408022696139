import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  LinearProgress,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import ProfileIcon from "@mui/icons-material/Person";
import { useAppSelector } from "../../store/store";
import { addUserForm } from "../../store/thunk/formThunk";
import { anonymousUser } from "../../helper/service";

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData") || "{}")
    : null;
  const localIsBoostrapCalled = localStorage.getItem("isBootstrapCalled");
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showHeader, setShowHeader] = useState(true);
  const [isProgressbarVisible, setIsProgressbarVisible] = useState(false);
  const [isIframe, setIsIframe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { flowDataLoading } = useAppSelector(
    (state: any) => state.notificationReducer
  );
  const { progressData } = useAppSelector(
    (state: any) => state.documentReducer
  );

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);

  const localClickButton = localStorage.getItem("buttonClicked");
  const lockedApp = localStorage.getItem("lockedApp");

  const exitFromEmulation = () => {
    sessionStorage.removeItem("Usapath-Emulate-UserId");
    sessionStorage.removeItem("Usapath-Emulate-UserScope");
    sessionStorage.removeItem("Usapath-Session-Id");
    sessionStorage.removeItem("unSavedFormValues");
    if (isIframe) {
      localStorage.clear();
    }

    localStorage.setItem("buttonClicked", "Admin");
    const isInIframe = window.self !== window.top;
    let baseUrl = window.FORM_UI_URL;
    // const hostname = window.location.hostname;

    // if (hostname === "localhost") {
    //   baseUrl = "http://localhost:3001";
    // } else if (hostname === "my-dev.usapath.com") {
    //   baseUrl = "https://my-dev.legalcaseapp.com";
    // } else {
    //   baseUrl = "https://my.legalcaseapp.com";
    // }
    baseUrl = baseUrl.replace("/iframe", "");
    let redirectUrl;

    if (isInIframe) {
      redirectUrl = `${baseUrl}/client`;
      window.parent.postMessage(
        { type: "REDIRECT", url: redirectUrl },
        baseUrl
      );
    } else {
      redirectUrl = "/form_emulation";
      window.location.href = redirectUrl;
    }
  };

  useEffect(() => {
    setIsIframe(window.self !== window.top);
  }, []);

  useEffect(() => {
    const isInIframe = window !== window.parent;

    if (isInIframe) {
      if (progressData) {
        const geSection = progressData?.find(
          (sectionData: any) =>
            sectionData?.sectionKey === "General_Eligibility"
        );

        if (
          (bootstrapData &&
            !bootstrapData?.isDisabled &&
            !bootstrapData?.isDeleted &&
            geSection) ||
          isInIframe
        ) {
          setIsProgressbarVisible(true);
        }
      }
    } else {
      if (progressData) {
        const geSection = progressData?.find(
          (sectionData: any) =>
            sectionData?.sectionKey === "General_Eligibility"
        );

        if (
          bootstrapData &&
          !bootstrapData?.isDisabled &&
          !bootstrapData?.isDeleted &&
          geSection &&
          geSection?.percentComplete >= 88
        ) {
          setIsProgressbarVisible(true);
        }
      } else {
        setIsProgressbarVisible(false);
      }
    }
  }, [progressData, bootstrapData]);

  useEffect(() => {
    if (location?.pathname?.includes("/admin") && anonymousUser()) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location]);

  const handleUnLocking = async (flag: boolean) => {
    const tempUnSavedFormValues: any =
      sessionStorage.getItem("unSavedFormValues");
    const tempUpdatedFormValues: any =
      sessionStorage.getItem("updatedFormValues");

    let updatedFormValues = JSON.parse(tempUpdatedFormValues);
    let unSavedFormValues = JSON.parse(tempUnSavedFormValues);

    updatedFormValues = {
      ...updatedFormValues,
      ["ui_form_lock_state"]: flag,
    };

    await dispatch(
      addUserForm({
        form_data: JSON.stringify(updatedFormValues),
      })
    ).then((res: any) => {
      if (res?.payload?.data) {
        sessionStorage.setItem(
          "unSavedFormValues",
          JSON.stringify({ ...unSavedFormValues, ["ui_form_lock_state"]: flag })
        );
        localStorage.setItem("lockedApp", JSON.stringify(flag));
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  if (isLoading && localIsBoostrapCalled === "true") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <CircularProgress size={60} />
        <Typography
          variant="h6"
          sx={{ mt: 2, fontWeight: "bold", color: "#555" }}
        >
          Making things ready for you, please wait...
        </Typography>
      </Box>
    );
  }
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <div>
      <div className="banner-height">
        {sessionStorage.getItem("Usapath-Emulate-UserId") && (
          <Box
            sx={{
              display: "flex",
              borderWidth: "2px",
              borderColor: "#da3b38",
              borderRadius: "8px",
              background: "#fccaca",
              fontWeight: "500",
              color: "#da3b38",
              py: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="emulating-user">
              Currently Emulating User -
              {localStorage.getItem("Usapath-Emulate-User")}
              {isIframe && (
                <span className="space-between">
                  {sessionStorage.getItem("Usapath-Emulate-UserName")}
                </span>
              )}
            </div>
            <Button
              type="button"
              variant="text"
              sx={{
                color: "#da3b38",
                fontSize: "14px",
                textTransform: "none",
                fontWeight: "700",
                p: 0,
              }}
              onClick={exitFromEmulation}
            >
              Exit
            </Button>
          </Box>
        )}

        {lockedApp === "true" && (
          <Box
            sx={{
              display: "flex",
              borderWidth: "2px",
              borderColor: "#da3b38",
              borderRadius: "8px",
              background: "#dc724180",
              fontWeight: "500",
              color: "#da3b38",
              py: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Application is currently in a locked state —
            <Button
              type="button"
              variant="text"
              sx={{
                color: "#da3b38",
                fontSize: "14px",
                textTransform: "none",
                fontWeight: "700",
                p: 0,
              }}
              onClick={() => handleUnLocking(false)}
            >
              <LockOpenOutlinedIcon />
              Click here to unlock
            </Button>
          </Box>
        )}
      </div>
      <header
        className="flex items-center justify-center bg-[#fff] md:px-[2rem] md:py-[25px] md:pb-[0px] 2xl:pb-[25px] border-b border-solid border-[#E5E7EB] header-height"
        style={!showHeader ? { display: "none" } : {}}
      >
        <div className="inner-header w" style={{ width: "100%" }}>
          <nav className="border-gray-200">
            <div
              className={`  w-full overflow-auto custom-scrollbar ${
                isProgressbarVisible || isIframe ? "" : "hideScroll"
              }`}
            >
              {location.pathname === "/upload-document" && (
                <span className="Sen-Bold self-center text-sm md:text-lg font-extrabold max-w-[60%] whitespace-nowrap overflow-hidden text-ellipsis text-[#0C1932] m-[-0.875rem]">
                  Upload Documents
                </span>
              )}

              {(localClickButton !== "Admin" || isIframe) && (
                <Box className="progressBarSecond">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        maxWidth: { xs: 320, sm: "100%" },
                        bgcolor: "background.paper",
                      }}
                    ></Box>
                    <IconButton
                      onClick={handleProfileMenuOpen}
                      className="profile-btn"
                      sx={{ mr: 2, color: "primary.main" }}
                    >
                      <ProfileIcon fontSize="large" />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleProfileMenuClose}
                      sx={{ mt: "45px" }}
                    >
                      <div style={{ padding: "10px 16px" }}>
                        <Typography
                          variant="body1"
                          sx={{ mb: 1, color: "#aaa" }}
                        >
                          {userData?.nickname}
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#aaa" }}>
                          {userData?.email}
                        </Typography>
                      </div>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  </div>
                </Box>
              )}
            </div>
          </nav>
        </div>
      </header>
      {flowDataLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </div>
  );
};

export default HeaderComponent;
