import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  downloadCSV,
  downloadVisibilityConditionRef,
  getFlowMappingHistory,
  getVcrHistory,
  uploadCSV,
  uploadVisibilityConditionRef,
} from "../../store/thunk/formThunk";
import ReactJson from "react-json-view";
import History from "./History";

const CsvManagement = () => {
  const [value, setValue] = useState("1");
  const [selectedCsv, setSelectedCsv] = useState("CSV");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedFileError, setSelectedFileError] = useState<string>("");
  const [uploadResult, setUploadResult] = useState<any>();
  const [uploadHistory, setUploadHistory] = useState<any>([]);
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getFlowMappingHistory()).then((res: any) => {
      if (res?.payload?.status === 200) {
        setUploadHistory(res?.payload?.data);
      }
    });
  }, []);
  const handleCsv = ({ target: { value } }: any) => {
    setSelectedCsv(value);

    dispatch(value === "CSV" ? getFlowMappingHistory() : getVcrHistory()).then(
      (res: any) => {
        if (res?.payload?.status === 200) {
          setUploadHistory(res?.payload?.data);
        }
      }
    );
  };
  const handleDownload = () => {
    if (selectedCsv === "CSV") {
      dispatch(downloadCSV());
    } else {
      dispatch(downloadVisibilityConditionRef());
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const allowedFileTypes = [".csv", ".tsv", ".txt"];
    const fileName = selectedFile?.name || "";
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    if (selectedFile === null) {
      setSelectedFileError(
        "Please select any .csv, .tsv or .txt file to upload."
      );
    }

    if (!allowedFileTypes.includes(fileExtension)) {
      setSelectedFileError(
        "Please select any .csv, .tsv or .txt file to upload."
      );
      return;
    } else {
      setSelectedFileError("");
    }
    if (selectedCsv === "CSV") {
      dispatch(uploadCSV(selectedFile)).then((res: any) => {
        if (res?.payload?.status === 200) {
          setUploadResult(res?.payload?.data);
          setSelectedFile(null);
          setSelectedFileError("");
        }
      });
    } else {
      dispatch(uploadVisibilityConditionRef(selectedFile)).then((res: any) => {
        if (res?.payload?.status === 200) {
          setSelectedFile(null);
          setSelectedFileError("");
        }
      });
    }
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        className="header-tabbar"
      >
        <Tab label="Upload/Download" value={"1"} />
        <Tab label="History" value={"2"} />
      </Tabs>
      {value === "1" && (
        <Box sx={{ p: "20px" }}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel
              sx={{ pr: 1, background: "white" }}
              id="demo-simple-select-label"
            >
              CSV
            </InputLabel>
            <Select
              value={selectedCsv}
              name="CaseType"
              onChange={(e: any) => handleCsv(e)}
            >
              <MenuItem value={"CSV"}>Flow Mapping</MenuItem>
              <MenuItem value={"VisibilityConditionRef"}>
                Visibility Condition Ref
              </MenuItem>
            </Select>
          </FormControl>
          <form onSubmit={handleSubmit}>
            <div className="flex my-5 items-center">
              <strong className="me-2">Choose a file:</strong>
              <Button variant="contained" component="label">
                Upload File
                <input
                  type="file"
                  accept={".csv, .tsv, .txt"}
                  className="hidden"
                  onChange={(e: any) => setSelectedFile(e.target.files[0])}
                />
              </Button>
              <Typography sx={{ ml: 2 }}>{selectedFile?.name}</Typography>
            </div>
            {selectedFileError && (
              <Typography sx={{ ml: 2, color: "red" }}>
                {selectedFileError}
              </Typography>
            )}
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                type="submit"
                sx={{
                  px: "10px",
                  py: "5px",
                  background: "#2557b3",
                  color: "#fff",
                  fontSize: "14px",
                  ":hover": {
                    backgroundColor: "rgba(37, 87, 179, 0.9)",
                  },
                }}
              >
                Upload
              </Button>
              <Button
                type="button"
                onClick={handleDownload}
                sx={{
                  px: "10px",
                  py: "5px",
                  background: "#2557b3",
                  color: "#fff",
                  fontSize: "14px",
                  ":hover": {
                    backgroundColor: "rgba(37, 87, 179, 0.9)",
                  },
                }}
              >
                Download
              </Button>
            </Box>
            <Box>
              <div
                className="jsonViewer"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <p>
                  <strong className="me-2">Result:</strong>
                </p>
                <ReactJson
                  src={uploadResult || {}}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
              </div>
            </Box>
          </form>
        </Box>
      )}

      {value === "2" && <History {...{ uploadHistory }} />}
    </>
  );
};

export default CsvManagement;
