import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Button,
  DialogTitle,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  Typography,
  Paper 
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  addB2BEntityLogo,
  disabledKey,
  enableKey,
  getB2BEntityLogo,
  getBillingDetails,
  resetPrimaryKey,
  resetSecondaryKey,
} from "../../store/thunk/billingThunk";
import dotnetApiClient from "../../config/dotnetApiClient";
import { logErrorFunction, logFunction } from "../../helper/service";
import LogoComponent from "./LogoComponent";

export function ViewBillingEntityDialog({ open, handleClose, EntityId }: any) {
  const allowedFileTypes = ["image/png"];
  const dispatch = useAppDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPrimaryKey, setShowPrimaryKey] = useState(false);
  const [showSecondaryKey, setShowSecondaryKey] = useState(false);
  const { entityDetails, setEntityDetails } = useAppSelector(
    (state: any) => state.billingReducer
  );
  const [userPersona, setUserPersona] = useState<string>("Lawyer");
  const [users, setUsers] = useState<any[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const accessToken = localStorage.getItem("accessToken") || null;
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<any>("");
  const [imageUrl, setImageUrl] = useState<any>("");

  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    userPersona: "Lawyer",
  });
  const [creatingUser, setCreatingUser] = useState(false);
  const [showNewUserForm, setShowNewUserForm] = useState(false);

  const handleLogout = () => {
    const isIframe = window.self !== window.top;
    const hostname = window.location.hostname;
    let redirectUrl = '';
    localStorage.clear();
    sessionStorage.clear();

    if (isIframe) {
      redirectUrl = window.FORM_UI_URL;
      // if (hostname === 'localhost') {
      //   redirectUrl = 'http://localhost:3001';
      // } else if (hostname === 'my-dev.usapath.com') {
      //   redirectUrl = 'https://my-dev.legalcaseapp.com';
      // } else {
      //   redirectUrl = 'https://my.legalcaseapp.com';
      // }
      if (window.top) {
        window.top.location.href = redirectUrl;
      }
    } else {
      redirectUrl = window.CURRENT_HOST;
      // if (hostname === 'localhost') {
      //   redirectUrl = 'http://localhost:3000';
      // } else if (hostname === 'my-dev.usapath.com') {
      //   redirectUrl = 'https://my-dev.usapath.com';
      // } else {
      //   redirectUrl = 'https://my.usapath.com';
      // }
    }
  }

  useEffect(() => {
    const fetchEntityDetails = async () => {
      try {
        const response = await dotnetApiClient().get(
          `/UiUser/billingentity/${EntityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data) {
          setEntityDetails(response.data);
        } else {
          logErrorFunction("No data returned from API.");
        }
      } catch (error: any) {
        logErrorFunction("Error fetching entity details:" + error);
        if (error?.response?.status === 401 || error?.response?.status === 406) {
          handleLogout()
        }
      }
    };

    fetchEntityDetails();
  }, [EntityId, accessToken]);

  const fetchEntityLogo = async () => {
    setLoadingImage(true);
    await dispatch(getB2BEntityLogo({ id: EntityId })).then((res: any) => {
      setImageUrl(res?.payload);
      setLoadingImage(false);
    })
  };

  useEffect(() => {
    if (EntityId) {
      fetchEntityLogo();
    }
  }, [EntityId]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoadingUsers(true);
      try {
        const response = await dotnetApiClient().get(
          `/UiUser/billingentity/${EntityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUsers(response.data);
      } catch (error: any) {
        logErrorFunction("Error fetching users:" + error);
        if (error?.response?.status === 401 || error?.response?.status === 406) {
          handleLogout()
        }
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchUsers();
  }, [EntityId, accessToken]);

  const handleDeleteUser = async (userId: string) => {
    try {
      await dotnetApiClient().put(`/UiUser/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const response = await dotnetApiClient().get(
        `/UiUser/billingentity/${EntityId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setUsers(response.data);
    } catch (error: any) {
      logErrorFunction("Error deleting user:" + error);
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
    }
  };

  useEffect(() => {
    if (EntityId) {
      setBtnLoading(true);
      dispatch(getBillingDetails({ id: EntityId })).finally(() => {
        setBtnLoading(false);
      });
    }
  }, [EntityId, dispatch]);

  const onResetKey = (keyType: string) => {
    const _request = { id: entityDetails?.id };
    setBtnLoading(true);
    if (keyType === "primaryKey") {
      dispatch(resetPrimaryKey(_request)).finally(() => {
        setBtnLoading(false);
      });
    } else {
      dispatch(resetSecondaryKey(_request)).finally(() => {
        setBtnLoading(false);
      });
    }
  };

  const toggleVisibility = (keyType: string) => {
    if (keyType === "primaryKey") {
      setShowPrimaryKey(!showPrimaryKey);
    } else {
      setShowSecondaryKey(!showSecondaryKey);
    }
  };

  const fields = [
    { label: "Id", value: entityDetails?.id },
    { label: "Name", value: entityDetails?.billingName },
    { label: "Type", value: entityDetails?.billingType },
    { label: "Disabled on", value: entityDetails?.disabledOn },
    { label: "Billing Id", value: entityDetails?.billingId },
  ];

  const enableDisableLabel = entityDetails?.disabledOn ? "Enable" : "Disable";

  const handleEnableDisable = () => {
    const _request = { id: entityDetails?.id };
    if (entityDetails?.disabledOn) {
      dispatch(enableKey(_request)).finally(() => {
        setBtnLoading(false);
      });
    } else {
      dispatch(disabledKey(_request)).finally(() => {
        setBtnLoading(false);
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setNewUser((prevUser) => ({ ...prevUser, userPersona: e.target.value }));
  };

  // Handle new user form submission
  const handleCreateUser = async () => {
    setCreatingUser(true);
    try {
      await dotnetApiClient().post(
        "/UiUser/lawfirm/user",
        {
          ...newUser,
          billingEntityId: EntityId,
          createdBy: EntityId,
          userPersona: newUser.userPersona,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const response = await dotnetApiClient().get(
        `/UiUser/billingentity/${EntityId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setUsers(response.data);
      setNewUser({
        name: "",
        email: "",
        phoneNumber: "",
        userPersona: "Lawyer",
      });
      setShowNewUserForm(false);
    } catch (error: any) {
      logErrorFunction("Error creating user:" + error);
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
    } finally {
      setCreatingUser(false);
    }
  };

  // Extract billingType from entityDetails
  const billingType = entityDetails?.billingType;

  const validateFile = (file: File) => {
    const maxFileSize = 1 * 1024 * 1024; // 1MB

    if (!allowedFileTypes.includes(file.type)) {
      setFileError("Invalid file type. Allowed type: PNG only");
      return false;
    }
    if (file.size > maxFileSize) {
      setFileError("File size exceeds 1MB limit.");
      return false;
    }
    setFileError("");
    return true;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (validateFile(file)) {
        setFile(file);
      }
    }
  };


  const handleLogoSubmit = async () => {
    let logo_params = {
      id: entityDetails?.id,
      logo_file: file
    };

    await dispatch(addB2BEntityLogo(logo_params)).then((res: any) => {
      fetchEntityLogo();
    })
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Billing Entity Details</DialogTitle>
      <DialogContent>
        {btnLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="userViewDetail">
              <div className="logo_container">
                <div>
                  {fields.map((field) => (
                    <div key={field.label} style={{ marginBottom: "10px" }}>
                      <strong>{field.label}:</strong> {field.value || "-"}
                    </div>
                  ))}
                </div>
                <div>

                  {loadingImage && (<CircularProgress />)}

                  {imageUrl
                    && <div><img src={imageUrl} alt="Logo" className="lawyer_logo" /></div>}
                  <div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ marginBottom: "16px" }}
                    />
                    {file && <Button
                      variant="outlined"
                      onClick={() => handleLogoSubmit()}
                      sx={{ mr: '10px' }}
                    >Submit</Button>}
                    {fileError && <Typography sx={{ fontSize: '12px', color: 'red' }}>{fileError}</Typography>}
                  </div>

                </div>

              </div>
              {["Primary", "Secondary"].map((key) => (
                <div className="basicDetails" key={key}>
                  <div style={{ marginBottom: "10px" }}>
                    <strong>{key} Key:</strong>{" "}
                    {key === "Primary"
                      ? showPrimaryKey
                        ? entityDetails?.primaryKey
                        : "************"
                      : showSecondaryKey
                        ? entityDetails?.secondaryKey
                        : "************"}
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mr: 2 }}
                      onClick={() =>
                        toggleVisibility(`${key.toLowerCase()}Key`)
                      }
                    >
                      {key === "Primary"
                        ? showPrimaryKey
                          ? "Hide"
                          : "Show"
                        : showSecondaryKey
                          ? "Hide"
                          : "Show"}{" "}
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => onResetKey(`${key.toLowerCase()}Key`)}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              ))}
            </div>

            <div>
              <Button
                variant="contained"
                color={entityDetails?.disabledOn ? "success" : "error"}
                onClick={handleEnableDisable}
                disabled={btnLoading}
              >
                {enableDisableLabel}
              </Button>
            </div>
            {billingType === "LawyerApp" && (
              <div className="upload-logo-container">
                <Button
                  variant="outlined"
                  onClick={() => setShowNewUserForm(!showNewUserForm)}
                  sx={{ mr: '10px' }}
                >
                  {showNewUserForm ? "Cancel" : "Add New User"}
                </Button>
              </div>
            )}

            {billingType === "LawyerApp" && (
              <>
                {showNewUserForm && (
                  <div className="new-form">
                    <TextField
                      label="Name"
                      name="name"
                      value={newUser.name}
                      onChange={handleInputChange}
                      sx={{marginRight: '10px', maxWidth: '24%'}}
                    />
                    <TextField
                      label="Email"
                      name="email"
                      value={newUser.email}
                      onChange={handleInputChange}
                      sx={{marginRight: '10px'}}
                    />
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      value={newUser.phoneNumber}
                      onChange={handleInputChange}
                      sx={{marginRight: '10px'}}
                    />
                    <FormControl sx={{width: '25%'}}>
                      <InputLabel className="filter_by_user">User Persona</InputLabel>
                      <Select
                        value={newUser.userPersona}
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="Lawyer">Lawyer</MenuItem>
                        <MenuItem value="Client">Client</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      onClick={handleCreateUser}
                      disabled={creatingUser}
                      className="adduser-btn"
                    >
                      {creatingUser ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Add User"
                      )}
                    </Button>
                  </div>
                )}

                <div className="mt-[30px]">
                  <FormControl className="select_user">
                    <InputLabel className="filter_by_user">Filter by User Persona</InputLabel>
                    <Select
                      value={userPersona}
                      onChange={(e) => setUserPersona(e.target.value as string)}
                    >
                      <MenuItem value="Lawyer">Lawyer</MenuItem>
                      <MenuItem value="Client">Client</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <TableContainer component={Paper} className="mt-[20px]">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{fontWeight: '700'}}>Name</TableCell>
                        <TableCell sx={{fontWeight: '700'}}>Email</TableCell>
                        <TableCell sx={{fontWeight: '700'}}>Phone Number</TableCell>
                        <TableCell sx={{fontWeight: '700'}}>User Persona</TableCell>
                        <TableCell sx={{fontWeight: '700'}}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loadingUsers ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : (
                        users && users?.length > 0 ?
                          users.filter((user) => user.userPersona === userPersona)
                            .map((user) => (
                              <TableRow key={user.id}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.phoneNumber}</TableCell>
                                <TableCell>{user.userPersona}</TableCell>
                                <TableCell>
                                  <Button
                                    color="error"
                                    onClick={() => handleDeleteUser(user.id)}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )) :
                          <TableRow><TableCell colSpan={5}><Typography sx={{ color: '#aaa', display: 'flex', justifyContent: 'center' }}>No data found</Typography></TableCell></TableRow>)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )
        }
      </DialogContent >
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog >
  );
}
