import { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Select,
  FormControl,
  MenuItem,
  Button,
  IconButton,
  DialogTitle,
  Dialog,
} from "@mui/material";
import moment from "moment";
import { getUserList } from "../../store/thunk/userThunk";
import { useAppSelector, useAppDispatch } from "../../store/store";
import "./index.css";
import {
  getFlowMappingCaseTypes,
  getMappingData,
} from "../../store/thunk/billingThunk";
import { getFlowMappingIds } from "../../store/thunk/formThunk";

const FlowForm = (props: any) => {
  let {
    billingEntities,
    formState,
    setFormState,
    formInputChange,
    formNameList,
    formVersionList: formVersions,
    addNewForm,
  } = props;
  const dispatch: any = useAppDispatch();
  const [isDialog, setIsDialog] = useState(false);
  const [formName, setFormName] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const [mappingList, setMappingList] = useState([]);
  const [castTypeList, setCaseTypeList] = useState<any>([]);
  const { getSignleFormData } = useAppSelector(
    (state: any) => state?.formReducer
  );

  useEffect(() => {
    const userAPICall = async () => {
      await dispatch(getFlowMappingCaseTypes()).then((res: any) => {
        const responseData = res?.payload;

        if (responseData) {
          const tempRes = responseData?.find(
            (c: any) => c?.billingInformation?.id === formState?.billingEntityId
          )?.caseTypes;
          setTimeout(() => {
            setFormState((p: any) => ({
              ...p,
              flow: tempRes?.[0]?.id,
              version: tempRes?.[0]?.mappingId,
            }));
          }, 0);

          setCaseTypeList(responseData);
        }
      });
    };

    userAPICall();
  }, [formState?.billingEntityId]);

  useEffect(() => {
    const userAPICall = async () => {
      await dispatch(getMappingData()).then((res: any) => {
        const responseData = res?.payload;

        if (responseData) {
          setMappingList(responseData);
        }
      });
    };

    userAPICall();
  }, []);
  useEffect(() => {
    const userAPICall = async () => {
      await dispatch(getUserList()).then((res: any) => {
        const responseData = res?.payload?.data;
        if (responseData) {
          responseData?.map((data: any, index: number) => {
            if (getSignleFormData?.createdBy == data?.id) {
              setCreatorName(data.email);
            }
          });
        }
      });
    };

    userAPICall();
  }, [getSignleFormData]);

  const handleClose = () => {
    setIsDialog(false);
  };
  const handleOpen = () => {
    setIsDialog(true);
  };

  const handleFormName = (event: any) => {
    let formName = event.target.value;
    setFormName(formName);
  };

  const addName = () => {
    addNewForm(formState.billingEntityId, formName);
    handleClose();
  };
  const handleFlowChange = (e: any) => {
    const tempRes = castTypeList
      ?.find(
        (c: any) => c?.billingInformation?.id === formState?.billingEntityId
      )
      ?.caseTypes?.find((c: any) => c?.id === e.target.value)?.mappingId;
    setTimeout(() => {
      setFormState((p: any) => ({ ...p, version: tempRes }));
    }, 0);
  };
  const handleSave = async () => {
    const tempCaseTypeData = castTypeList?.filter(
      (x: any) => x.caseTypes?.[0]?.id === formState?.flow
    );
    let passedDataToMappping = {
      MappingId: formState?.version,
      caseId: formState?.flow,
    };

    await dispatch(getFlowMappingIds(passedDataToMappping)).then((res: any) => {
      const responseData = res?.payload;

      // if (responseData) {
      //   const tempRes = responseData?.find(
      //     (c: any) => c?.billingInformation?.id === formState?.billingEntityId
      //   )?.caseTypes;
      //   console.log(tempRes, "tempRes");
      //   setTimeout(() => {
      //     setFormState((p: any) => ({ ...p, mappingId: tempRes?.[0]?.id }));
      //   }, 0);

      //   setCaseTypeList(responseData);
      // }
    });
  };
  return (
    <div className="flex items-center align-center mb-[16px] overflow-auto">
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ fontWeight: "700" }}>Billing Entity</label>
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small1"
            value={formState?.billingEntityId}
            label="Billing Entity"
            name="billingEntityId"
            onChange={formInputChange}
          >
            {billingEntities &&
              castTypeList?.map((c: any) => {
                return (
                  <MenuItem value={c?.billingInformation?.id}>
                    {c?.billingInformation?.billingName}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>

      <div style={{ display: "flex", alignItems: "center", marginRight: 0 }}>
        <label style={{ fontWeight: "700" }}>Case Type</label>
        <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
          <Select
            labelId="demo-select-small-label1"
            id="demo-select-small1"
            value={formState?.flow || ""}
            label="name"
            name="flow"
            onChange={(e) => {
              formInputChange(e);
              handleFlowChange(e);
            }}
          >
            {castTypeList &&
              castTypeList.length > 0 &&
              castTypeList
                ?.find(
                  (c: any) =>
                    c?.billingInformation?.id === formState?.billingEntityId
                )
                ?.caseTypes?.map((list: any, index: any) => {
                  return (
                    <MenuItem key={index} value={list?.id}>
                      {list.title + " - " + list.version + ""}
                    </MenuItem>
                  );
                })}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: 24,
          alignSelf: "center",
        }}
        className="form-add-btn"
      >
        <IconButton
          style={{
            background: "#ccc",
            height: "31px",
            width: "31px",
          }}
          aria-label="delete"
          onClick={handleOpen}
        >
          +
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ fontWeight: "700" }}> Mapping</label>
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small1"
            value={formState?.version}
            label="Version"
            name="version"
            onChange={formInputChange}
          >
            {mappingList &&
              mappingList?.map((m: any) => {
                return <MenuItem value={m?.mappingId}>{m?.name}</MenuItem>;
              })}
          </Select>
        </FormControl>
      </div>

      <div style={{ display: "flex", alignItems: "center", marginRight: 0 }}>
        <Button onClick={() => handleSave()}>Save</Button>
      </div>

      <Dialog onClose={handleClose} open={isDialog}>
        <DialogTitle>Create case type</DialogTitle>
        <Box
          component="form"
          style={{ padding: "0 20px", width: "350px" }}
          sx={{
            "& > :not(style)": { m: 1, width: "300px" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            name="formName"
            onChange={handleFormName}
          />
          <Button variant="contained" onClick={addName}>
            Add
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default FlowForm;
