import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { formatDate } from "../../helper/service";
const History = ({ uploadHistory }: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState<any>([]);
  const [userList, setUserList] = useState<any>([]);

  useEffect(() => {
    setUserList(uploadHistory);
    setUserData(uploadHistory);
  }, [uploadHistory]);

  const generateColumn = (name: string, label: string, options = {}) => ({
    name,
    label,
    options: {
      sort: true,
      ...options,
    },
  });
  const columns = [
    generateColumn("id", "Id"),
    generateColumn("fileType", "FileType"),
    generateColumn("startedAt", "StartedAt", {
      customBodyRender: (value: any) => <span>{formatDate(value)}</span>,
    }),
    generateColumn("endedAt", "EndedAt", {
      customBodyRender: (value: any) => <span>{formatDate(value)}</span>,
    }),
    generateColumn("runBy", "RunBy"),
    generateColumn("stats", "Stats"),
    generateColumn("fileURL", "FileURL", {
      customBodyRender: (value: any) => (
        <a href={value} target="_blank" style={{ color: "#1976d2" }}>
          Download
        </a>
      ),
    }),
  ];
  const options = {
    sort: true,
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    // serverSide: true,
    rowsPerPageOptions: [],
    page: currentPage > 1 ? currentPage - 1 : 0,
    count: userList?.length,
    rowsPerPage: 10,
    selectableRowsHideCheckboxes: true,
    pagination: true,
  };

  const handleSort = (column: string, order: string) => {
    const sortedData = [...userData];
    sortedData?.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (order === "asc") {
        return aValue < bValue ? -1 : 1;
      } else {
        return bValue < aValue ? -1 : 1;
      }
    });

    // Update the state with the sorted data
    setUserList(sortedData);
    setUserData(sortedData);
  };
  const handleSearchChange = (searchText: string | null) => {
    const searchedUsers = searchText
      ? userList?.filter((user: any) =>
          user?.fileType?.toLowerCase()?.includes(searchText?.toLowerCase())
        )
      : userList;
    setUserData(searchedUsers);
  };
  return (
    <div>
      {" "}
      <MUIDataTable
        title={"CSV file upload history"}
        data={userData}
        columns={columns}
        options={{
          ...options,
          search: false,
          onColumnSortChange: handleSort,
          onSearchChange: handleSearchChange,
        }}
      />
    </div>
  );
};

export default History;
